.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.listWrap{
  margin: 20px 10px;
}
.title{
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.content{
  text-align: left;
  color: #666;
  border-top: 1px solid #eee;
  margin-top: 20px;
}
.hash{
  word-wrap:break-word;
  border-bottom: 1px solid #eee;
  padding:10px;
  font-size: 14px;
}
.hash span{
color: #06c;
}
.pdf-privew .textLayer,.pdf-privew .annotationLayer{
  height: 0 !important;
}
.load-warp{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center
}
.load-warp .nut-configprovider {
  padding: 40px 50px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;

}
.load-warp .nut-configprovider .nut-loading .nut-loading-icon-box .nut-loading-icon{
  color: #F9F9F9 !important;
  width: 40px !important;
  height: 40px !important;
}
.load-warp .nut-configprovider .nut-loading .nut-loading-text{
  color: #F9F9F9 !important;
  font-size: 14px !important;
}
.cell-warp{
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
}
.cell-label{color: #666;text-align: right;}
.cell-value{flex: 1;text-align: right;color: #222;padding-left: 12px;font-weight: 600;}
.s-card{
  padding: 12px;
  background-color: #f5f5f5;
}
.s-card-warp{
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 14px;
  padding: 12px;
}
.s-card-title{
 padding: 6px 14px;
 border-bottom: 1px solid #e5e5e5;

}